import * as React from "react"
import { Layout } from "../components/layout"
import { Helmet } from 'react-helmet'
import { BlackboxData } from "../products";
import { PriceBox } from "../components/price-box";

const Title = "Blackbox - Audio Mastering Software";
const Description = BlackboxData.Description;

const BlackboxPage = () => {
  return (
    <Layout>
      <Helmet defer={false}>
        <title>{Title}</title>
        <meta name="description" content={Description} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={Title} />
        <meta property="og:description" content={Description} />
        <meta property="og:image" content="https://ianknowles.com/content/blackbox/blackbox-ecard.png" />
      </Helmet>
      <section className="hero">
        <div className="container">
            <div className="text-center">
              <img src="/content/blackbox/blackbox-icon.svg" alt="Blackbox Logo - Ian Knowles" />
            </div>
            <h1 className="text-center">Blackbox</h1>
            <p className="text-center">{Description}</p>
            <div className="top-spacer text-center">
              <p>Download current verion 2.1.5</p>
              <a href={BlackboxData.Downloads.WinX64} className="download-button"><i className="n-icon">&#xf325;</i> Windows</a>
              <a href={BlackboxData.Downloads.OSX} className="download-button"><i className="n-icon">&#xf179;</i> Mac OSX</a>
            </div>
        </div>
      </section>

      <section className="alt">
        <div className="container">
          <h2>{Title}</h2>
          <PriceBox product_code="IK_BLACKBOX" />
        </div>
      </section>

      <section className="dark">
        <div className="container">
          <h2>What is Blackbox</h2>
          <p>
            Blackbox is a mastering grade processing suite intended to make your music sound polished.
            Blackbox faithfully emulates a natural analog style mastering chain and accurately configures the settings based on user preference.
            Blackbox adds punch, loudness and quality to your mix, whilst providing the overall balance found in many of today's top productions.
            Blackbox is having your music mastered with some simple dials and presets the way you want it to sound.
            Make your demos sound great and give your streamable media the wow factor.
          </p>
          <p>
            Blackbox masters and improves the quality of almost any genre of music
          </p>
          <ul>
            <li>Dance / EDM</li>
            <li>Rock</li>
            <li>Pop</li>
            <li>Techno / Tech House</li>
            <li>Trance / Progressive</li>
            <li>Indie</li>
            <li>Electronic</li>
            <li>R&amp;B / Hip Hop</li>
            <li>Breakbeat</li>
            <li>Jungle / Drum &amp; Bass</li>
            <li>+ many more</li>
          </ul>
          <p className="text-center">
            <a href="/blackbox/wiki" className="btn btn-primary">BLACKBOX WIKI / DOCUMENTATION</a>
          </p>
        </div>
      </section>

      <section className="alt">
        <div className="container">
          <h2>Installation</h2>
          <p><strong>Windows</strong> - download the zip file and extract the binary executable. Put the executable file where you desire and create a shortcut to it if you wish. The Blackbox.exe is standalone so should just run from any location.</p>
          <p><strong>Mac</strong> - download the dmg file and extract the binary application. Put the application in the applications folder on your mac. Run the application. If you get a security warning saying it can't be run as settings only allow applications from Mac App Store, don't worry. Goto Apple -&gt; System Preferences -&gt; Security &amp; Privacy and you should see a notification about &quot;BlackBox was blocked&quot;, click on &quot;Open Anyway&quot; which should allow the program to run!</p>
          <p className="note">Please note that Blackbox uses SSE instruction set so computers with CPU's older than 2012 may have issues. Please check that the programs runs on your machine before purchasing.</p>
          <div className="top-spacer text-center">
            <p><strong>Download Blackbox!</strong></p>
            <a href={BlackboxData.Downloads.WinX64} className="download-button"><i className="n-icon">&#xf325;</i> Windows</a>
            <a href={BlackboxData.Downloads.OSX} className="download-button"><i className="n-icon">&#xf179;</i> Mac OSX</a>
            <p>
              Windows binary <a href={BlackboxData.Downloads.WinX64}>Blackbox.zip</a><br />
              Mac OSX Application <a href={BlackboxData.Downloads.OSX}>Blackbox.dmg</a>
            </p>
          </div>
        </div>
      </section>

      <section className="dark">
        <div className="container grid-col2">
          <div>
            <h3>Control</h3>
            <p>8 simple controls that will make your songs or tracks sound great</p>
            <ul>
              <li>Low Frequency Cut</li>
              <li>Sub Enhance</li>
              <li>Body Level</li>
              <li>Clarity</li>
              <li>Brightness</li>
              <li>Loudness</li>
              <li>Compression</li>
              <li>Quality</li>
            </ul>
          </div>
          <div className="pad-on-break">
            <img src="/content/blackbox/II/BBXII2.png" className="img-cover full-width" alt="Blackbox II - Screenshot" />
          </div>
        </div>
      </section>

      <section className="alt">
        <div className="container">
          <h3>Change log</h3>

          <p><strong>Version 2.1.5</strong></p>
          <p className="note">This is a service release intended to fix potential issues.</p>
          <ul>
            <li>Migrated brand from Nammick to Ian Knowles</li>
            <li>Upgraded framework to latest standards</li>
            <li>Minor bug fixes</li>
          </ul>

          <p><strong>Version 2.1.4</strong></p>
          <p className="note">This is a service release intended to fix potential issues.</p>
          <ul>
            <li>Fixed mid side stereo bug getting channels swapped</li>
            <li>Updated FFT display to show a greater range</li>
            <li>Fixed bug which caused an out of range exception on some windows machines</li>
          </ul>

          <p><strong>Version 2.1.3</strong></p>
          <p className="note">This is a service release intended to fix potential issues.</p>
          <ul>
            <li>Fixed a bug which impacted bass on multiband compressor stage</li>
            <li>Fixed issue that was causing clipping under certain conditions</li>
          </ul>

          <p><strong>Version 2.1.2</strong></p>
          <p className="note">This is a service release intended to fix potential issues.</p>
          <ul>
            <li>Fixed several UI/UX workflow bugs</li>
            <li>Sanitized the FFT display</li>
            <li>Fixed a potential issue where zombie data may cause a crash on certian systems</li>
          </ul>

          <p><strong>Version 2.1.1</strong></p>
          <p className="note">This is a service release intended to fix potential issues. The rules on saving in trial mode have also changed to allow for saving with intermittent silences.</p>
          <ul>
            <li>Fixed several UI Bugs</li>
            <li>Added minor performance enhancements</li>
            <li>Improved internal memory management</li>
            <li>Added software / GPU rendering settings</li>
            <li>Added idle performance enhancement</li>
            <li>Added compatibility for older Intel and AMD chips</li>
            <li>Added MSI installer file for windows setup</li>
            <li>Fixed potential deadlock scenario</li>
            <li>Fixed potential race condition which may cause crash on load</li>
          </ul>

          <p><strong>Version 2.1.0</strong></p>
          <p className="note">The new realtime mode is an approximation of the final mastered audio. It should be used as a workflow tool only. We will also be dropping support for 32 bit builds from this release onwards due to backwards compatability causing instability issues.</p>
          <ul>
            <li>Fixed several UI Bugs</li>
            <li>Added realtime mode</li>
            <li>Added FFT view</li>
            <li>Added loudness metering</li>
            <li>Added RMS meter</li>
            <li>Added LUFS meter</li>
            <li>Added Integrated loudness meter</li>
            <li>Added Dynamic range meter</li>
          </ul>

          <p><strong>Version 2.0.0</strong></p>
          <ul>
            <li>Improved UI/UX</li>
            <li>Added workflow keyboard shortcuts</li>
            <li>Added SIMD performance enhancements</li>
            <li>Improved filter quality</li>
            <li>Added multiple sample-rate processing support</li>
            <li>Added full 16, 24 and 32 bit support with dithering options</li>
            <li>Improved transient response</li>
            <li>Added new presets</li>
            <li>Added limiting peak control</li>
            <li>Added BS.1770 compliant true peak option</li>
            <li>Added VU Metering</li>
            <li>General perfomance and bug fixes</li>
          </ul>

          <p>Download Blackbox 2.0.0 (binaries)</p>
          <p>
            Windows binary <a href="http://nammick.blob.core.windows.net/programs/Blackbox.2.0.0.zip">Blackbox.2.0.0.zip</a><br />
            Mac OSX Application <a href="http://nammick.blob.core.windows.net/programs/Blackbox.2.0.0.dmg">Blackbox.2.0.0.dmg</a>
          </p>

          <p><strong>Version 1.0.1 (legacy)</strong></p>

          <ul>
            <li>Lots of bug fixes</li>
            <li>Changed UI behavoir</li>
            <li>Add new preset</li>
            <li>Added support for any source audio sample-rate</li>
            <li>Added transient enhancement / recorvery</li>
            <li>Re-model of the compressor</li>
          </ul>

          <p>Download Blackbox 1.0.1 (binaries)</p>
          <p>
            Windows binary <a href="http://nammick.blob.core.windows.net/programs/Blackbox.1.0.1.zip">Blackbox.1.0.1.zip</a><br />
            Mac OSX Application <a href="http://nammick.blob.core.windows.net/programs/Blackbox.1.0.1.dmg">Blackbox.1.0.1.dmg</a>
          </p>
        </div>
      </section>
    </Layout>
  )
}
export default BlackboxPage